#job-length-modal div[class*="ModalDialogWrapper"] > span > div{
    width: calc(100% - -4px) !important;
    height: calc(100% - -2px) !important;
}
#job-length-modal div[class*="ModalDialogWrapper"] > span {
    height: calc(100% - -4px) !important
}

#job-length-modal > div >div >div >div{
    height: calc(100% - 70px);
}

#sticky-footer-bottom {
    position: sticky;
    bottom: 0px;
    left:  0px;
    background: white;
    z-index: 2;
    width: 100%;          
}