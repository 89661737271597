.p-dropdown-open,
.p-dropdown-filter {
  font-family: "Verizon-NHG-eTX";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da !important;
  border-radius: 6px !important;
  height: 34px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-multiselect-item {
  font-size: 14px;
  color: #000 !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #dddddd !important;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  border-color: #000 !important;
  background: #000 !important;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  color: #000 !important;
  background: #dee2e6 !important;
  border-radius: 16px !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border: 1px solid #000;
}

.p-multiselect-panel .p-multiselect-header {
  font-size: 14px !important;
  color: black !important;
}
.p-checkbox {
  margin-right: 0.4rem !important;
}
.p-multiselect .p-multiselect-label {
  background: transparent !important;
  width: 100% !important;
  display: inline-flex !important;
  appearance: none !important;
  outline: none !important;
  color: rgb(0, 0, 0) !important;
  line-height: 1.25rem !important;
  font-family: Verizon-NHG-eDS !important;
  letter-spacing: 0.03125rem !important;
  padding: 0.75rem 2.75rem 0.75rem 0.75rem !important;
}
