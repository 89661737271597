.react-calendar {
  font-family: Verizon-NHG-eDS;
  width: 475px;
  margin-top: 1rem;
  border: 1.7px solid #e6e6e6;
}

.react-calendar-double {
  font-family: Verizon-NHG-eDS;
  width: 700px;
  margin-top: 1rem;
  border: 1.7px solid #e6e6e6;
}

.react-calendar__tile {
  font-size: 1em;
  font-weight: bold;
  color: black;
  padding: 1.3rem 0.5rem 1.3rem 0.5rem;
  line-height: 20px;
}

.react-calendar__tile--now,
.react-calendar__tile--now:enabled:hover {
  background-color: white;
}

.react-calendar__tile--active::before {
  display: none;
}

.react-calendar__tile:disabled {
  background-color: white;
  color: lightgrey;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgba(0, 0, 0, 0.877) !important;
  color: white;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  font-size: 0.9rem;
}

.react-calendar__navigation button {
  font-size: 1rem;
  font-weight: 600;
}

.react-calendar__navigation .react-calendar__navigation__label:enabled:focus,
.react-calendar__navigation .react-calendar__navigation__label:enabled:active,
.react-calendar__navigation button:disabled {
  background-color: white;
}

.react-calendar__navigation .react-calendar__navigation__label:enabled:hover {
  background-color: #e6e6e6;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 2rem !important;
}

.react-calendar__navigation__label__labelText {
  font-size: 1.2rem;
}

.react-calendar__navigation {
  margin-top: 1em;
}

.react-calendar__month-view__weekdays {
  color: grey;
}

.text {
  display: inline-block;
  position: relative;
}

.text::before {
  transform: translateX(-50%);
  border-radius: 100%;
  position: absolute;
  background: rgb(60, 156, 60);
  bottom: 10px;
  height: 8px;
  content: "";
  width: 8px;
  left: 50%;
}

.react-calendar__tile--now:enabled:hover {
  background-color: #e6e6e6;
}

.info-grid {
  margin-top: 1rem;
}
