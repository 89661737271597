.p-dialog .p-dialog-header {
  padding: 1px !important;
}
@media (max-width: 950px) {
  .p-dialog .p-dialog-content {
    padding: 10px !important;
  }
}

.p-dialog-content {
  overflow-y: unset !important;
}
.StepperContainer-0-2-1 {
  padding-top: 1px !important;
  padding-bottom: 10px !important;
}

.p-inputtext {
  height: 45px !important;
  margin-top: 4px !important;
  width: 100% !important;
}

/* Stepper css  */
.step {
  background: #000 !important;
}

.content {
  margin-left: -85px !important;
  margin-right: -85px !important;
  font-family: "Neue Haas Grotesk Text Std" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  color: #000 !important;
  margin-top: 0 !important;
}

.title {
  color: #000 !important;
  text-align: center;
  font-family: "Neue Haas Grotesk Text Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
}

.progress-bar {
  background: #dbdada !important;
  height: 2px !important;
  width: 5.5rem !important;
}

.progress-bar:after {
  background: #dbdada !important;
  height: 2px !important;
}

.step.pending {
  border: 1px solid black !important;
  background: none !important;
  color: black !important;
}

.progress-bar.completed:after {
  background: #000 !important;
  height: 2px !important;
}

.step.completed {
  background: #000 !important;
  border: none !important;
  /* animation: none !important; */
}

.check-mark,
.sa-fix {
  background: #000 !important;
}

.sa-icon {
  background: #000 !important;
  border: 0.25em solid #000 !important;
  border-color: #000 !important;
}

.sa-icon::before,
.sa-icon::after {
  background: #000 !important;
}

@media (max-width: 679px) {
  .progress-bar {
    width: 100% !important;
  }
  .number {
    display: none !important;
  }
  .step {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  .animation-check-mark {
    display: none !important;
  }
}

/* Stepper css */
