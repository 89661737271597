/* added this file as part of VZC42148-106 */
.custom-filter .p-column-filter-buttonbar {
  padding: 0px !important;
}

.custom-filter .p-column-filter-constraint {
  padding: 0px !important;
}

.custom-filter .p-listbox .p-listbox-header .p-listbox-filter-icon {
  left: 0px !important;
  right: auto !important;
  color: #000000 !important;
}

.custom-filter .p-inputtext {
  left: 15px !important;
  border: none !important;
  font-family: "Verizon-NHG-eDS" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  width: 90% !important;
}

.custom-filter .p-listbox .p-listbox-header {
  background: #ffffff !important;
  padding: 0px 0px 0.5rem 0px !important;
}

.custom-filter .p-listbox .p-listbox-list .p-listbox-item {
  font-family: "Verizon-NHG-eDS" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  border-top: 1px solid #d8dada !important;
  padding: 12px 21px 12px 10px !important;
  color: black !important;
  line-height: 20px;
}

.custom-filter .p-listbox .p-listbox-list {
  padding: 0px !important;
}

.custom-filter .p-listbox {
  padding: 10px 10px 10px 15px !important;
  border-radius: 6px !important;
  border: none;
}

.custom-filter {
  border-radius: 12px !important;
  max-width: 382px !important;
  max-height: 310px !important;
  width: -webkit-fill-available !important;
}

.custom-filter .p-listbox-item.p-highlight {
  background: #dddddd !important;
}

.custom-filter .p-listbox-item:focus {
  box-shadow: inset 0 0 0 0.15rem #dddddd !important;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
  background: #dddddd !important;
}
